import axios from 'axios';

export const baseURL = `https://api.iamunderwriter.com/v10`; // Prod Client Server

export default axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});
